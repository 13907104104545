.floatBtnContent {
  text-align: center;
  z-index: 1000;
  width: calc(100% - 62px);
  height: 54px;
  bottom: 15px;
  background: var(--app-primary-color);
}

.content {
  // margin-top: 50px;
  margin: 80px 24px 30px;

  color: var(--app-text-color);
  text-align: center;
  // margin-bottom: 184px;
  // height: 300px;
  padding: 50px 0;
  background: linear-gradient(180deg, #f77a40 0%, #ff9767 100%);
  background-image: url('/images/hairstyle/home-tryon-bottom-icon.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 28px;

  > .title {
    font-size: 22px;
    font-weight: bold;
    color: #ffffff;
    line-height: 50px;
  }

  > .desc {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    line-height: 28px;
    padding-top: 12px;
    padding-left: 20px;
    padding-right: 20px;
  }

  > .btnContainer {
    display: inline-block;
    position: relative;

    > .signUpBtn {
      // min-width: 300px;
      border: none;
      padding: 0 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 54px;
      border-radius: 5px;
      margin-top: 42px;
      font-size: 16px;
      color: #ffffff;
      background: linear-gradient(90deg, #647cff 0%, #9f3eff 100%);
      padding: 0 60px;
    }
  }
}

@media screen and (min-width: 899px) {
  .floatBtnContent {
    display: none;
  }
  .content {
    display: block;
    // margin-bottom: 164px;
  }
}

@media (max-width: 900px) and (min-width: 768px) {
  .floatBtnContent {
    display: none;
  }
  .content {
    display: block;
    // margin-bottom: 134px;
  }
}

@media (max-width: 768px) and (min-width: 576px) {
  .floatBtnContent {
    display: none;
  }
  .content {
    display: block;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 576px) {
  .floatBtnContent {
    display: block;
  }
  .content {
    display: none;
    margin: 50px 0;
  }
}

